import { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [color, setColor] = useState(0);

  useEffect(() => {
    var handle = setInterval(() => {
      setColor((color + 1)%360)
      console.log(color)
    }, 50)
    return () => {clearInterval(handle)}
  });

  return (
    <div id="page" style={{backgroundColor:"hsl(" + color + ", 50%, 50%)"}}>
      <div id="page-content">
        <div id="app-icon">
          <img src="./icon.svg" alt="icon" />
        </div>
        <div className="title" id="main-title">StoneTracker</div>
        <div className="title" id="under-title">Coming soon</div>

      </div>
    </div>
  );
}

export default App;
